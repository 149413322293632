#header {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 9997;
    transition: all 0.5s;
    padding: 15px;
    /*overflow-y: auto;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    }
  
    @media (max-width: 992px) {
    #header {
      bottom: 85%;
    }
    .nav-menu a {
      width: 70px !important;
      padding: 5px 9px !;
      font-size: 12px !important;
      height: 30px !important;
      text-align: center !important;
    }
    .nav-menu ul  li{
      display: inline-flex !important;
       font-size: 8px;
    }
  
    .nav-menu a i {
    font-size: 12px !important;
    }
  
    .nav-menu a span {
    padding: 0 2px 0 5px !important;
    color: #45505b;
  }
  
  }
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /* Desktop Navigation */
  .nav-menu * {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .nav-menu > ul > li {
    position: relative;
    white-space: nowrap;
  }
  
  .nav-menu a {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #607d8b;
    padding: 4px 15px;
    margin-bottom: 8px;
    transition: 0.3s;
    font-size: 15px;
    border-radius: 50px;
    background: #ffffff;
    height: 45px;
    width: 100%;
    overflow: hidden;
    transition: 0.3s;
  }
  
  .nav-menu a i {
    font-size: 20px;
  }
  
  .nav-menu a span {
    padding: 0 5px 0 7px;
    color: #45505b;
  }
  
  @media (min-width: 992px) {
    .nav-menu a {
      width: 20px;
    }
    .nav-menu a span {
      display: none;
      color: #fff;
    }
  }
  
  .nav-menu a:hover, .nav-menu .active > a, .nav-menu li:hover > a {
    color: #fff;
    background: #607d8b;
  }
  
  .nav-menu a:hover span, .nav-menu .active > a span, .nav-menu li:hover > a span {
    color: #fff;
  }
  
  .nav-menu a:hover, .nav-menu li:hover > a {
    width: 100%;
    color: #fff;
  }
  
  .nav-menu a:hover span, .nav-menu li:hover > a span {
    display: block;
  }
  
  /* Mobile Navigation */
  .mobile-nav-toggle {
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 9998;
    border: 0;
    background: none;
    font-size: 24px;
    transition: all 0.4s;
    outline: none !important;
    line-height: 1;
    cursor: pointer;
    text-align: right;
  }
  
  .mobile-nav-toggle i {
    color: #45505b;
  }
  
  .mobile-nav-active {
    overflow: hidden;
  }
  
  .mobile-nav-active #header {
    left: 0;
  }
  
  .mobile-nav-active .mobile-nav-toggle i {
    color: #0563bb;
  }
  
  
  
  /*--------------------------------------------------------------
  # Descargar
  --------------------------------------------------------------*/
  .tienda{
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  
  .playstore{
    width: 50%;
    text-align: right;
  }
  
  .appstore{
    width: 50%;
    text-align: left;
  }
  
  @media (max-width: 400px) {
  
    .playstore{
    width: 100%;
    text-align: center;
  }
  
  .appstore{
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
  }
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
    background: #f7f8f9;
    color: #45505b;
    font-size: 14px;
    text-align: center;
    padding: 30px 0;
  }
  
  #footer h3 {
    font-size: 36px;
    font-weight: 700;
    position: relative;
    font-family: "Poppins", sans-serif;
    padding: 0;
    margin: 0 0 15px 0;
    line-height: 1;
  }
  
  #footer p {
    font-size: 15;
    font-style: italic;
    padding: 0;
    margin: 0 0 40px 0;
  }
  
  #footer .social-links {
    margin: 0 0 40px 0;
  }
  
  #footer .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #0563bb;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .social-links a:hover {
    background: #0678e3;
    color: #fff;
    text-decoration: none;
  }
  
  #footer .copyright {
    margin: 0 0 5px 0;
  }
  
  #footer .credits {
    font-size: 13px;
  }
  