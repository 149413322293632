#header {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 9997;
    transition: all 0.5s;
    padding: 15px;
    /*overflow-y: auto;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 992px) {
    #header {
        bottom: 85%;
    }

    .nav-menu a {
        width: 70px !important;
        padding: 5px 9px !;
        font-size: 12px !important;
        height: 30px !important;
        text-align: center !important;
    }

    .nav-menu ul li {
        display: inline-flex !important;
        font-size: 8px;
    }

    .nav-menu a i {
        font-size: 12px !important;
    }

    .nav-menu a span {
        padding: 0 2px 0 5px !important;
        color: #45505b;
    }

}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu * {
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav-menu>ul>li {
    position: relative;
    white-space: nowrap;
}

.nav-menu a {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #607d8b;
    padding: 4px 15px;
    margin-bottom: 8px;
    transition: 0.3s;
    font-size: 15px;
    border-radius: 50px;
    background: #ffffff;
    height: 45px;
    width: 100%;
    overflow: hidden;
    transition: 0.3s;
}

.nav-menu a i {
    font-size: 20px;
}

.nav-menu a span {
    padding: 0 5px 0 7px;
    color: #45505b;
}

@media (min-width: 992px) {
    .nav-menu a {
        width: 20px;
    }

    .nav-menu a span {
        display: none;
        color: #fff;
    }
}

.nav-menu a:hover,
.nav-menu .active>a,
.nav-menu li:hover>a {
    color: #fff;
    background: #607d8b;
}

.nav-menu a:hover span,
.nav-menu .active>a span,
.nav-menu li:hover>a span {
    color: #fff;
}

.nav-menu a:hover,
.nav-menu li:hover>a {
    width: 100%;
    color: #fff;
}

.nav-menu a:hover span,
.nav-menu li:hover>a span {
    display: block;
}

/* Mobile Navigation */
.mobile-nav-toggle {
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 9998;
    border: 0;
    background: none;
    font-size: 24px;
    transition: all 0.4s;
    outline: none !important;
    line-height: 1;
    cursor: pointer;
    text-align: right;
}

.mobile-nav-toggle i {
    color: #45505b;
}

.mobile-nav-active {
    overflow: hidden;
}

.mobile-nav-active #header {
    left: 0;
}

.mobile-nav-active .mobile-nav-toggle i {
    color: #0563bb;
}

#hero {
    width: 100%;
    height: 72vh;
    background: url("./img/inicio.jpeg") top right no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (min-width: 992px) {
    #hero {
        padding-left: 160px;
    }
}

#hero:before {
    content: "";

    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#hero h1 {
    margin: 0;
    font-size: 7em;
    font-weight: 700;
    line-height: 100px;
    color: #607d8b;
    position: sticky;
    text-align: left;
    padding-left: 10%;
}

#hero p span {
    color: #4caf50;
    letter-spacing: 1px;
}

#hero .social-links {
    margin-top: 30px;
}

#hero .social-links a {
    font-size: 24px;
    display: inline-block;
    color: #45505b;
    line-height: 1;
    margin-right: 20px;
    transition: 0.3s;
}

#hero .social-links a:hover {
    color: #0563bb;
}

@media (max-width: 992px) {
    #hero {
        text-align: center;
    }

    #hero h1 {
        font-size: 4em;
    }

    #hero p {
        margin-top: 10px;
        line-height: 50px;
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.prefix__stroke-solid {
    stroke-dashoffset: 0;
    stroke-dashArray: 300;
    stroke-width: 4px;
    transition: stroke-dashoffset 1s ease, opacity 1s ease;
}

.prefix__icon {
    transform: scale(.8);
    transform-origin: 50% 50%;
    transition: transform 200ms ease-out;
}

#play:hover .prefix__stroke-solid {
    opacity: 1;
    stroke-dashoffset: 300;
}

#play:hover .prefix__icon {
    transform: scale(.9);
}

#play {
    cursor: pointer;
    top: 50%;
    margin-left: 40%;
    margin-right: auto;
    transform: translateY(50%) translateX(-100%);
}

/*--------------------------------------------------------------
  # Descargar
  --------------------------------------------------------------*/
.tienda {
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
    padding-bottom: 30px;
}

.playstore {
    width: 50%;
    text-align: right;
}

.appstore {
    width: 50%;
    text-align: left;
}

@media (max-width: 400px) {

    .playstore {
        width: 100%;
        text-align: center;
    }

    .appstore {
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }
}

/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
#footer {
    background: #f7f8f9;
    color: #45505b;
    font-size: 14px;
    text-align: center;
    padding: 30px 0;
}

#footer h3 {
    font-size: 36px;
    font-weight: 700;
    position: relative;
    font-family: "Poppins", sans-serif;
    padding: 0;
    margin: 0 0 15px 0;
    line-height: 1;
}

#footer p {
    font-size: 15;
    font-style: italic;
    padding: 0;
    margin: 0 0 40px 0;
}

#footer .social-links {
    margin: 0 0 40px 0;
}

#footer .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #0563bb;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#footer .social-links a:hover {
    background: #0678e3;
    color: #fff;
    text-decoration: none;
}

#footer .copyright {
    margin: 0 0 5px 0;
}

#footer .credits {
    font-size: 13px;
}